<template>
  <div>
<!--    <a-card :body-style="{ padding: 0 }" :bordered="false" style="text-align: center; margin-bottom: 24px;">-->
<!--      <div style="font-weight: bold; font-size: 22px;">主页</div>-->
<!--    </a-card>-->
    <div
      v-for="(item, i) in homeIndexGroup"
      :key="i"
    >
      <a-card
        v-if="item.children && !item.hidden"
        class="project-list"
        style="margin: 25px 0;"
        :bordered="false"
        :body-style="{padding: '0', margin: '15px'}">
        <template slot#header>
          <div class="item-card-title">
            <a-icon v-show="item.meta.icon" :type="item.meta.icon"/>
            <img v-show="!item.meta.icon" src="~@/assets/logo.png">
            <span style="margin-left: 10px">{{item.meta && item.meta.title}}</span>
          </div>
        </template>
        <a-card-grid
          v-for="(value, index) in item.children"
          :key="index"
          class="menu-item"
          v-if="!value.hidden"
          @click="handleMenuJump(value)">
          <a-icon v-show="value.meta.icon" :type="value.meta.icon" style="font-size: 44px;" />
          <img v-show="!value.meta.icon" style="width: 44px;" src="~@/assets/logo.png" class="logo" alt="logo">
          <div style="margin-top: 5px">
            {{ value.meta && value.meta.title }}
          </div>
        </a-card-grid>
      </a-card>
      <a-card v-if="!item.children && !item.hidden" class="sigin-wrap" :body-style="{ padding: 0 }" :bordered="false">
        <a-card-grid class="sigin-card menu-item" @click="handleMenuJump(item)" :hoverable="true">
          <a-icon v-show="item.meta.icon" :type="item.meta.icon" style="font-size: 44px;" />
          <img v-show="!item.meta.icon" style="width: 44px;" src="~@/assets/logo.png" class="logo" alt="logo">
          <div>
            {{ item.meta && item.meta.title }}
          </div>
        </a-card-grid>
      </a-card>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Home',
  computed: {
    ...mapState({
      // 动态主路由
      routersTree: state => state.permission.addRouters
    })
  },
  data () {
    return {
      homeIndexGroup: [
        {
          groupName: '用户及权限管理',
          list: [
            {
              name: '用户管理'
            },
            {
              name: '角色管理'
            },
            {
              name: '权限节点'
            },
            {
              name: '组织管理'
            }
          ]
        },
        {
          groupName: '系统参数',
          list: [
            {
              name: '模块管理'
            },
            {
              name: '系统参数'
            },
            {
              name: '数据字典'
            }
          ]
        },
        {
          groupName: '系统维护',
          list: [
            {
              name: '登录日志'
            },
            {
              name: '业务日志'
            }
          ]
        }
      ]
    }
  },
  created () {
    const routes = this.routersTree.find(item => item.path === '/')
    const menus = (routes && routes.children) || []
    this.homeIndexGroup = menus.filter(item => item.path !== '/home')
  },
  methods: {
    handleMenuJump (value) {
      if (value.meta.target === '_blank') {
          window.open(value.path)
      } else {
        this.$router.push({ path: value.path })
      }
    }
  }
}
</script>

<style lang="less" scoped>
  //@import "./Workplace.less";

  .item-card-title {
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }

  .card-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }

  .project-item {
    display: flex;
    margin-top: 8px;
    overflow: hidden;
    font-size: 12px;
    height: 20px;
    line-height: 20px;

    a {
      color: rgba(0, 0, 0, 0.45);
      display: inline-block;
      flex: 1 1 0;

      &:hover {
        color: #1890ff;
      }
    }

    .datetime {
      color: rgba(0, 0, 0, 0.25);
      flex: 0 0 auto;
      float: right;
    }
  }

  .ant-card-meta-description {
    color: rgba(0, 0, 0, 0.45);
    height: 44px;
    line-height: 22px;
    overflow: hidden;
  }

  .menu-item {
    width: 25%;
    text-align: center;
    cursor: pointer;
  }
  .sigin-wrap {
    margin-bottom: 24px;
    border-top: 1px solid #e8e8e8;
    .sigin-card {
      //margin-bottom: 24px;
      //width: 100%;
    }
  }

  .card-title {
    font-size: 0;

    a {
      color: rgba(0, 0, 0, 0.85);
      margin-left: 12px;
      line-height: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: top;
      font-size: 14px;

      &:hover {
        color: #1890ff;
      }
    }
  }
</style>
